// Here you can add other styles
.bold {
	font-weight: bold;
}

.sidebar-logo {
	width: 175px;
}

.sub-menu-wrapper {
	text-align: center;
	margin: 0 0 20px 0;
	overflow: hidden;

	p {
		padding: 13.512px 16px;
		text-align: left;

		.icon-lg {
			width: 64px !important;
			margin-left: -16px;
		}
		margin: 0;
	}

	.MuiFormControl-root {
		margin-bottom: 10px;

		.MuiFormLabel-root, .MuiInputBase-input, .MuiIconButton {
			color: #fff;
		} 
	}

	.input-margin {
		margin: 0 16px;
		width: calc(100% - 32px);
	}
}

.filter-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 20px 0 20px;

	.filter {
		display: flex;
		align-items: center;
		padding: 5px 10px;
		border-radius: 30px;
		background-color: #e1efe7;
		color: #6aaf8b;
		border: 1px solid #6aaf8b;
		margin: 0 5px;

		svg {
			color: #dc4a46;
			margin-left: 10px;
			transition: all .3s ease;
			cursor: pointer;

			&:hover {
				opacity: .7;
			}
		}
	}
}

.breadcrumb {
	align-items: center;
}

.btn-popover {
	cursor: pointer;

	svg {
		margin: 0 0 0 10px;
		color: #321fdb;
	}

	.lms-nom {
		&.univo {
			color: #D47558;
		}

		&.learning {
			color: #6399ce;
		}
	} 
}

.icon:not(.icon-c-s):not(.icon-custom-size).icon-xs {
	width: 12px;
	height: 12px;
	font-size: 12px;
}

.tr-title {
	svg {
		margin: 0 0 2px 0;
		cursor: pointer;
	}
}

.result-client {
	width: 150px;
	padding: 0;
	margin: 0;

	li {
		list-style: none;
		margin: 0;
		padding: 10px;
		width: 100%;
		cursor: pointer;
		box-sizing: border-box;
		border-bottom: 1px solid #0d6efd;

		&:last-child {
			border: 0;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		&:first-child {
			border: 0;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		&:hover {
			background-color: rgb(219, 232, 244);
		}
	}
}

.popover-body {
	padding: 0;
}

.cross-red {
	background-color: #dc4a46;
	color: #fff;
	border-radius: 20px;
	position: absolute;
	right: -10px;
	top: -10px;
	cursor: pointer;
}

.content-popover {
	padding: 10px;
}

.day-paiement-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.day-care {
		background-color: #2eb85c;
		border-radius: 50%;
		height: 35px;
		width: 35px;
		margin: 0 0px 10px 0;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;

		&.red {
			background-color: #dc3545;
		}
	}
}

.paiement-methode {
	width: 110px;
	background-color: #2eb85c;
	color: #fff;
	padding: 4px 5px;
	border-radius: 5px;
	text-align: center;
	font-size: 14px;
	margin: 0 auto;
}

.error {
	color: #dc3545;
}

.btn-valider {
	width: 100%;
}

.icon-pdf-wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-evenly;

	.wrapper-icon {
		position: relative;

		.icon-check {
			position: absolute;
			top: -5px;
			right: -5px;
			z-index: 2;
			border-radius: 50%;
			color: #fff;
			padding: 2px;

			&.green {
				background-color:#2eb85c;
			}

			&.red {
				background-color:#dc3545;
			}
		}
	}
}

.pdf-logo {
	width: 25px;
	cursor: pointer;
}

.full-width {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: 10px 0;
}

.icon-link {
	cursor: pointer;
}

.questionnaire-wrapper {
	width: 100%;

	h5 {
		font-size: 18px;
	}
	p {
		display: flex;
		justify-content: space-between;
	}
}

.btn-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
}

.canva-wrapper {
	text-align: center;
	margin: 20px 0 0 0;

	canvas {
		border: 1px solid #000;
	}
}

.j-minus-end {
	width: 75px;
	background-color: #dc3545;
	color: #fff;
	padding: 5px 0;
	border-radius: 5px;
	text-align: center;
	font-weight: bold;
	margin: 0 auto;
}

.end-date-div {
	text-align: center;
}

.completion-btn {
	background-color: #0d6efd;
	color: #fff;
	padding: 5px;
	border-radius: 5px;
	margin: 0 auto;
	cursor: pointer;
	font-size: 14px;
	text-align: center;
}

.top-margin {
	margin-top: 10px;
}

.completion-nb {
	color: #2eb85c;
	margin: 0 auto;
	font-weight: bold;
}

.pagination {
	margin: 10px auto 0;
	cursor: pointer;
}

.full-width-dates {
	width: 100%;
	display: flex;
	justify-content: space-between;
}